import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { fetch_delete, fetch_get, fetch_patch, fetch_post, fetch_put } from "../../../utils/fetch"
import { sleep } from "../../../utils/common"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"

function ProjectLinksDisplay(props) {
  let project = props.project
  const { getAccessTokenSilently } = useAuth0()
  var [links, setLinks] = useState([])
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })

  function getAdditionalUrlParams() {
    var urlparams = ""
    urlparams = `?parentOrganization=${project.parentOrganization}`
    return urlparams
  }
  const LoadLinks = async () => {
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    await sleep(500)
    fetch_get(`projects/${project.projectSlug}/links${getAdditionalUrlParams()}`)
      .then(response => {
        setLinks(response.data)
        setLoadStatus({ loading: false, error: false, errorMessage: null })
      })
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting workgroup links list: ${error}`)
      })
  }

  useEffect(() => {
    if (_.isNil(project.projectSlug) || _.isNil(project.parentOrganization)) return
    if (project.status === "archived") return
    LoadLinks()
  }, [project.projectSlug, project.projectSlug])

  return (
    <React.Fragment>
      <>
        <div>
          {loadStatus.loading && !loadStatus.error && (
            <>
              <div className="spinner-grow spinner-grow-sm" role="status"></div> Loading links..
            </>
          )}
          {!loadStatus.loading && !loadStatus.error && (
            <>
              {/* {links.length === 0 && <div>Sorry ! No workgroup links found</div>} */}
              {links.length > 0 && (
                <>
                  {/* <span>Website: </span> */}
                  {links.map((p, index) => (
                    <>
                      <a
                        key={`ProjectLink${index}`}
                        className="badge badge-success"
                        href={p.url}
                        target="_blank"
                        style={styles.badge}
                      >
                        {p.display_text}
                      </a>
                      &nbsp;
                    </>
                  ))}
                </>
              )}
            </>
          )}
          {!loadStatus.loading && loadStatus.error && (
            <div className="alert alert-danger py-1" role="alert">
              {loadStatus.errorMessage}
            </div>
          )}
        </div>
      </>
    </React.Fragment>
  )
}

const styles = {
  badge: {
    display: "inline-block",
    background: "#99cd33",
    color: "#41474c",
  },
}

export default ProjectLinksDisplay
